import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Home.css';
import Nav from './Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Logo from './logo';
import Footer from './Footer';
import Contact from './Contact';
import Aboutus from './Aboutus';

function App() {
  

  return (
    <div>
      {/* <div>
        <center><img src='logo.jpg' width='25%'></img></center>
      </div>
      <div style={{margin:'14px', paddingRight:'40px'}}>home</div>
      <div style={{margin:'14px', paddingRight:'40px'}}>About Us</div>
      <div style={{margin:'14px', paddingRight:'40px'}}>Contact Us</div> */}
      <BrowserRouter>
      <Logo/>
      <Nav/>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/aboutus' element={<Aboutus/>}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
      </Routes>
      <Footer/>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
