import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

function Navbar() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
//   function scrollToSection(sectionId) {
//     var section = document.getElementById(sectionId);
//     if (section) {
//         section.scrollIntoView({ behavior: 'smooth' });
//     }
// };

  return (
    <div className="mainnavdiv" style={{zIndex:"10000000"}}>
    <nav className="navbar">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link onClick={scrollToTop} to="/" className="nav-link">Home</Link>
        </li>
        <li className='dot'>•</li>
        <li className="nav-item">
          <Link onClick={scrollToTop} to='/aboutus' className="nav-link">About Us</Link>
        </li>
        <li className='dot'>•</li>
        <li className="nav-item">
          <Link onClick={scrollToTop} to="/contact" className="nav-link">Contact Us</Link>
        </li>
        
      </ul>
    </nav>
    </div>
  );
}

export default Navbar;
