// import Slidemenu from './Reactoffcanvas'
import './Home.css';

function Logo(){
    return(
        <div style={{backgroundColor:'white'}}> 

        {/* <Slidemenu/> */}
        <div className='div0'>
        <center><img src="logo.jpg" alt="logo" width="25%" className='logo'></img></center>
        </div>
        <div className='div1'>
        <center><img src="logo.jpg" alt="logo" width="38%" className='logo'></img></center>
        </div>
        </div>
        
    );
    
}

export default Logo;