import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer(){

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
        });
      };
    //   function scrollToSection(sectionId) {
    //     var section = document.getElementById(sectionId);
    //     if (section) {
    //         section.scrollIntoView({ behavior: 'smooth' });
    //     }
    // };

    return(
        <>
          
            <footer className="site-footer">
                
                <div className="container">
                    <div className="row ">
                    <div className="col-sm-12 col-md-6">
                    <img src="footerlogo.png" alt="logo" width="50%" className='logo'></img>
                    </div>

                    <div className="col-xs-6 col-md-3 ">
                        <h6>Contact details</h6>
                        {/* 123 Maple Street

                        <br></br> Springfield, Imaginaria 56789<br></br> 140301<br></br> */}

                        Phone:  <a href="tel:+1 (226)500-0855">+1 (226) 500-0855</a><br></br>
                        <i style={{color:"white"}}>Phone:</i>  <a href="tel:+91 7837371535">+91 7837371535</a>
                        <br></br>
                        <div className='d-flex rowwrap'>
                        <a href="mailto: Connectuniverseimmigration@gmail.com">Email: Connectuniverseimmigration@gmail.com</a>
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>


                    </div>

                    <div className="col-xs-6 col-md-3">
                        <h6>Quick Links</h6>
                        <ul className="footer-links">
                        <li><Link to="/" onClick={scrollToTop}>Home</Link></li>

                        <li><Link to="/contact" onClick={scrollToTop}>Contact Us</Link></li>
                        <li><Link onClick={scrollToTop} to="/aboutus" >About</Link></li>
                        
                        
                        </ul>
                    </div>
                    </div>
                    <hr className='mb-4'></hr>
                    
                </div>
                <div className="container">
                    <div className="row">
                    <div className="col-md-8 col-sm-6 col-xs-12">
                        <p className="copyright-text">Copyright &copy; 2024 Connect universe. All rights reserved. Website created by <a href='https://github.com/sqmridhi'><i class="fa-brands fa-github"></i></a>
                        </p>
                    </div>

                    {/* <div className="col-md-4 col-sm-6 col-xs-12">
                        <ul className="social-icons">
                        <li><Link to="/" onClick={scrollToTop} className="facebook"><i className="fa fa-whatsapp"></i></Link></li>
                        <li><Link to="/" onClick={scrollToTop} className="twitter"><i className="fa fa-instagram"></i></Link></li>
                        <li><Link to="/" onClick={scrollToTop} className="dribbble"><i className="fa-solid fa-envelope"></i></Link></li>
                        <li><Link to="/" onClick={scrollToTop} className="linkedin"><i className="fa fa-linkedin"></i></Link></li>
                        
                        <li><a className="facebook" href="#"><i className="fa fa-facebook"></i></a></li>
                        <li><a className="twitter" href="#"><i className="fa fa-twitter"></i></a></li>
                        <li><a className="dribbble" href="#"><i className="fa fa-dribbble"></i></a></li>
                        <li><a className="linkedin" href="#"><i className="fa fa-linkedin"></i></a></li>   
                        </ul>
                    </div> */}
                    </div>
                </div>
            </footer>
            
        </>
    );
}

export default Footer;