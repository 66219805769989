import './about.css';
import './Footer.css';

function Aboutus(){
    return(
        <div>
            <img src="sky.jpg" className="img-fluid"></img>
            <br></br>
            <br></br>

            <h1 style={{fontFamily:'myFirstFont',color:'black',fontSize:'xx-large'}} className="ms-3" >About Us</h1>
            <div className='m-3'>
            <h6><b>Our Origin Story</b></h6>
            <p>Established in 2022, Connect Universe Immigration Services was founded with the goal of providing clients from all walks of life with individualized solutions and unflinching assistance. Having a profound awareness of the difficulties faced by immigrants, Tanvir Kaur set out to establish a company that promotes integrity, trust, and a sense of belonging in addition to providing outstanding legal services.</p>
            <h6><b>Our Mission and Vision</b></h6>
            <p>We at Connect Universe Immigration Services are aware of how immigration may change a society. Our journey started more than 10 years ago with the goal of offering thorough and compassionate immigration services to people, families, and businesses navigating the complexities of the immigration process.</p>
            </div>
            
            <div className='d-flex row flex-wrap justify-content-around m-3 mt-4'>
    
            <div className="card col-md-4 me-3 mb-3">
            <div className="bg"><center><h5 className='pt-3 fw-bold'>Reputation and Recognition</h5><p className='pe-3 ps-3'>Serving customers locally, nationally, and worldwide, Connect Universe Immigration Services has developed into a reputable fixture in the immigration law field over the years. Because of our firm's unwavering commitment to quality, we have been recognized for our devotion to positive outcomes and client advocacy.</p> </center></div>
            <div className="blob"></div>
            </div>
    
            <div className="card me-3 mb-3">
            <div className="bg"><center><h5 className='pt-3 fw-bold'>Client-Centered Approach</h5><p className='pe-3 ps-3'>Motivated by our passion for justice and equality, we have assisted numerous people in reaching their immigration objectives, be they acquiring permanent residence, obtaining visas, or negotiating the difficulties of becoming citizens. With a wealth of knowledge and experience, our team of skilled lawyers ensures that every client receives individualized attention and tactics that are specifically designed to fit their needs.</p> </center></div>
            <div className="blob"></div>
            </div>
            <div className="card me-3 mb-3">
            <div className="bg"><center><h5 className='pt-3 fw-bold'>Community Engagement</h5><p className='pe-3 ps-3'>As an organization, we value community and cooperation highly. To advance awareness, empowerment, and education, we actively collaborate with immigrant communities, charitable organizations, and advocacy groups. We work hard to give our customers most recent information and strategic advice by remaining ahead of advances in immigration</p> </center></div>
            <div className="blob"></div>
            </div>
            <div className="card me-3 mb-3">
            <div className="bg"><center><h5 className='pt-3 fw-bold'>Commitment to Core Values</h5><p className='pe-3 ps-3'>We are committed to upholding our core values of quality, compassion, and integrity as we move forward. We are here to support you at every stage of your immigration journey, whether you are starting a new chapter in your life or are encountering difficulties.</p> </center></div>
            <div className="blob"></div>
            </div>
            </div>
            <br></br>
            <p className='ps-3 pe-3'>Welcome to Your immigration ambitions become our mission at Connect Universe Immigration Services!</p>
            <ul className="social-icons ps-3">
<li><a className="facebook me-3" href="https://wa.me/+12265000855"><i className="fa fa-whatsapp"></i></a></li>
<li><a className="twitter me-3" href="https://www.instagram.com/connectuniverseimmigration?igsh=ZWI2YzEzYmMxYg=="><i className="fa fa-instagram"></i></a></li>
<li><a className="linkedin me-3" href="mailto: Connectuniverseimmigration@gmail.com"><i className="fa-solid fa-envelope"></i></a></li>
</ul>
<br></br>
            <hr style={{color:'black'}}></hr>

        </div>
    );
}

export default Aboutus;