import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS CSS file
import { useEffect } from 'react';
import './Footer.css';


function Home(){
    useEffect(() => {
        AOS.init();
      }, []);
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
        });
      };
    return(
      <div style={{overflowX:'hidden'}}>

      <div className='cardcontainer'>
        <div className='text p-5' data-aos="fade-up" data-aos-duration="3000" ><b className='fs-3'>Navigating Borders, Building Dreams.</b><br></br><b style={{color:'brown'}} className='fs-5'>Make it happen</b><br></br>Our mission goes beyond mere immigration; it's about sculpting destinies and creating a roadmap for success. We understand that each journey is unique, and we are committed to tailoring our services to your individual aspirations. Our team stands as a beacon of support, ready to guide you through the intricate pathways of immigration, ensuring that every step is a stride towards your envisioned destination.<br></br><a href='https://wa.me/+12265000855'><button className='mt-2 btn btn-dark hovergreen'>Contact Us <i className='fa fa-whatsapp'></i></button></a></div>       
        <img className='image' src='home.png' width='50%'></img>
      </div>
      <hr style={{color:'black'}}></hr>

  {/* slide show end  */}
  
<br></br>
<br></br>
<center>
<h1 style={{fontFamily:'myFirstFont',color:'black'}}>Testimonies</h1>

</center>
<br></br>
<div style={{overflowX:'hidden'}}>
{/* row-1 */}
<div className='row text-center' >
  {/* row-1 column-1*/}
<div data-aos="fade-right" className='col-md-5 border border-secondary-subtle rounded-pill shadow-lg p-3 mb-5 bg-body-tertiary rounded col-10 m-auto mb-5'>

<div>
                                            <Link onClick={scrollToTop} to="/" className="text-decoration-none fs-5 fw-lighter text-dark linkpink">Loveleen Kaur</Link>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <div className='col-4'><hr class="center-ball border border-dark border-1 opacity-75"></hr></div>
                                                <div className='m-1 fs-5'><i class="fa-brands fa-canadian-maple-leaf brown"></i></div>
                                                <div className='col-4'><hr class="center-ball border border-dark border-1 opacity-75"></hr></div>
                                            </div>
                                            </div>
                                            <div className='d-flex col-10 m-auto'> 
                                            
                                            <p className='col- fs-6 align-self-center'>	"I cannot thank Connect Universe Immigration Services enough for their exceptional service! They guided me through the complex process of obtaining our Canadian Citizenship with professionalism and efficiency. Their expertise made what seemed like an overwhelming task manageable. Highly recommended!"</p>
                                            </div><br></br> 
</div>

{/* row-1 column-2 */}

<div data-aos="fade-left" className='col-md-5 border border-secondary-subtle rounded-pill shadow-lg pt-3 mb-5 bg-body-tertiary rounded col-10 m-auto mb-5'>
<div>
                                            <Link onClick={scrollToTop} to="/" className="text-decoration-none fs-5 fw-lighter text-dark linkpink">	Amandeep Singh</Link>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <div className='col-4'><hr class="center-ball border border-dark border-1 opacity-75"></hr></div>
                                                <div className='m-1 fs-5'><i class="fa-brands fa-canadian-maple-leaf brown"></i></div>
                                                <div className='col-4'><hr class="center-ball border border-dark border-1 opacity-75"></hr></div>
                                            </div>
                                            </div>
                                            <div className='d-flex col-10 m-auto'> 
                                            
                                            <p className='col- fs-6 align-self-center'>	"Choosing Connect Universe Immigration Services was the best decision I made for my immigration process (Permanent Residence for my spouse). Their team provided personalized attention and support, addressing all my concerns promptly. Their knowledge of immigration laws and attention to detail ensured a smooth and successful outcome. Thank you for making my dreams a reality!"</p>
                                            </div><br></br> 
</div>
</div>

{/* row-2 */}
<div className='row text-center'>
<div data-aos="fade-right" className='col-md-5 border border-secondary-subtle rounded-pill shadow-lg p-3 mb-5 bg-body-tertiary rounded col-10 m-auto mb-5'>
{/* row-2 column-1*/}
<div>
                                            <Link onClick={scrollToTop} to="/" className="text-decoration-none fs-5 fw-lighter text-dark linkpink">	Daljit Singh</Link>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <div className='col-4'><hr class="center-ball border border-dark border-1 opacity-75"></hr></div>
                                                <div className='m-1 fs-5'><i class="fa-brands fa-canadian-maple-leaf brown"></i></div>
                                                <div className='col-4'><hr class="center-ball border border-dark border-1 opacity-75"></hr></div>
                                            </div>
                                            </div>
                                            <div className='d-flex col-10 m-auto'> 
                                            
                                            <p className='col- fs-6 align-self-center'>	"I am extremely grateful to Tanvir for helping me reunite with my family in the U.S. Their dedication and commitment to our case were evident from the start. They navigated us through every step of the immigration process with clarity and professionalism. I highly endorse their services to anyone seeking immigration assistance."</p>
                                            </div><br></br> 
</div>

<div data-aos="fade-left" className='col-md-5 border border-secondary-subtle rounded-pill shadow-lg p-3 mb-5 bg-body-tertiary rounded col-10 m-auto mb-5'>
{/* row-2 column-2 */}
<div>
                                            <Link onClick={scrollToTop} to="/" className="text-decoration-none fs-5 fw-lighter text-dark linkpink">	Akashdeep Singh</Link>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <div className='col-4'><hr class="center-ball border border-dark border-1 opacity-75"></hr></div>
                                                <div className='m-1 fs-5'><i class="fa-brands fa-canadian-maple-leaf brown"></i></div>
                                                <div className='col-4'><hr class="center-ball border border-dark border-1 opacity-75"></hr></div>
                                            </div>
                                            </div>
                                            <div className='d-flex col-10 m-auto'> 
                                            
                                            <p className='col- fs-6 align-self-center'>	"Working with Connect Universe Immigration Services was a game-changer for me. Their team's expertise and guidance were invaluable in securing my work permit. They went above and beyond to ensure all documents were in order and deadlines were met. I couldn't have asked for a better partner in my immigration journey."</p>
                                            </div><br></br> 
    
</div>
 {/* row-1 column-1*/}
 <div data-aos="fade-right" className='col-md-5 border border-secondary-subtle rounded-pill shadow-lg p-3 mb-5 bg-body-tertiary rounded col-10 m-auto mb-5'>

<div>
                                            <Link onClick={scrollToTop} to="/" className="text-decoration-none fs-5 fw-lighter text-dark linkpink">	Arshdeep Singh</Link>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <div className='col-4'><hr class="center-ball border border-dark border-1 opacity-75"></hr></div>
                                                <div className='m-1 fs-5'><i class="fa-brands fa-canadian-maple-leaf brown"></i></div>
                                                <div className='col-4'><hr class="center-ball border border-dark border-1 opacity-75"></hr></div>
                                            </div>
                                            </div>
                                            <div className='d-flex col-10 m-auto'> 
                                            
                                            <p className='col- fs-6 align-self-center'>	"I can't express enough gratitude to Tanvir, Connect Universe for their unwavering support throughout my Permanent Residence application process. From the initial consultation to the final approval, they were with me every step of the way. Their knowledge, integrity, and professionalism are unparalleled. I wholeheartedly recommend their services to anyone navigating the complexities of immigration law."</p>
                                            </div><br></br> 
</div>
</div>

</div>

<hr style={{color:'black'}} ></hr>

{/* Testimonies end  */}

{/* about us */}
<br></br>
<br></br>
<center>
<h1 style={{fontFamily:'myFirstFont',color:'black'}}>About Us</h1>
<br></br>
</center>
<div className='row'>
<div className='col-sm-4 col-12'>
  <img src='logo.jpg' className='img-fluid col-12'></img>
</div>
<div className='aboutustext col-sm-8 col-12 pe-5 ps-5 ps-sm-0 pt-3 pt-sm-0'><p>Welcome to Connect Immigration – Your Trusted Partner in Navigating the Path to a Better Future!<br></br>

At Connect Immigration, we understand that the journey to immigration can be both exciting and challenging. Whether you are seeking new opportunities, reuniting with loved ones, or pursuing a brighter future, we are here to guide you every step of the way.<br></br>
<br></br>

Founded with a vision to simplify the immigration process, Connect Immigration is a dedicated team of professionals committed to providing comprehensive immigration services. With a wealth of experience and expertise, we specialize in assisting individuals, families, and businesses in achieving their immigration goals.<br></br>
</p>
<ul className="social-icons">
<li><a className="facebook me-3" href="https://wa.me/+12265000855"><i className="fa fa-whatsapp"></i></a></li>
<li><a className="twitter me-3" href="https://www.instagram.com/connectuniverseimmigration?igsh=ZWI2YzEzYmMxYg=="><i className="fa fa-instagram"></i></a></li>
<li><a className="linkedin me-3" href="mailto: Connectuniverseimmigration@gmail.com"><i className="fa-solid fa-envelope"></i></a></li>
</ul>
</div>
</div>
<br></br>


<hr style={{color:'black'}}></hr>



</div>



    );
}
export default Home;